@import '../../../../scss/theme-bootstrap';

.product-heading {
  &__name {
    @include font--heading;
    color: $color-green;
    margin: 0;
  }
  &__subheading {
    @include font--subheading;
    margin: 0;
  }
}
